class GameSortition {

    numbers;
    currentPosition;
    events;
    ended;

    TIMER_UPDATE = 1000;

    static SiGNAL_NAME = 'GAME_SORT';
    static ON_SORTITING = 'ON_SORTITING';
    static ON_START = 'ON_START';
    static ON_END = 'ON_END';

    constructor(){
        console.log('NEW');
        this.ended = false;
        this.events = {};
        this.numbers = Array(75).fill(0).map((e, i) => i + 1);
    }

    on(_name, _callback){
        if(!this.events[_name])
        this.events[_name] = [];
        this.events[_name].push(_callback);
    }

    triggerHandler = (_event, _params = null) => {
        if(this.events[_event]){
            this.events[_event].forEach(element => {
                element(_params);
            });
        }
    }

    randomizeNumbers = () => {
        this.numbers = this.numbers.sort(() =>  Math.random() * -1 + 0.5);
    }

    resetSortitionGame = () => {
        this.currentPosition = -1;
        this.randomizeNumbers();
    }

    callNumbers = () => {
        this.currentPosition += 1;

        if(this.currentPosition < this.numbers.length && !this.ended){
            this.triggerHandler(GameSortition.ON_SORTITING);
            setTimeout(this.callNumbers, this.TIMER_UPDATE);
        }else{
            this.triggerHandler(GameSortition.ON_END);
            console.log('End');
        }

    }

    setEnded = (_ended = true) => {
        console.log('Set END');
        this.ended = _ended;
    }

    setNumbers = (_numbers) => {
        this.numbers = _numbers;
    }

    getSortitionNumbers = () => {
        return this.numbers.filter((num, id) => {
            return id <= this.currentPosition;
        });
    }

    startGameSortition = () => {
        this.triggerHandler(GameSortition.ON_START);
        this.currentPosition = -1;
        this.ended = false;
        this.callNumbers();
    }

    getNumbers = (_i, _f) => {
        let list = [];

        for (let i = _i, ii = _f; i <= ii; i++){
            list.push(i);
        }

        list = list.sort((i) => (Math.random() * -1) + 0.5);
        list.length = 5;

        list.sort((a, b)=>  a-b);

        return  list; 
    }

    getTicketNumbers = () => {
        const ticket = [this.getNumbers(1, 15), this.getNumbers(16, 30), this.getNumbers(31, 45), this.getNumbers(46, 60), this.getNumbers(61, 75)];
        return ticket;
    }



    
}

export default GameSortition;