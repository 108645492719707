class Bot{

    static URL_BASE = 'https://gerador-nomes.herokuapp.com/nomes/';

    url;

    constructor(_total = 10){
        this.url = `${Bot.URL_BASE}${_total}`;
        console.log('BOOT')
    }

    generateBots = async () => {
        const request = await fetch(this.url);
        const json = await request.json();

        return json;
    }
}

export default Bot;