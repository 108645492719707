import React, {useEffect, useRef, useState} from 'react';

import Ball from '../ball';

import '../../../../assets/style/components/sortitionmachine/_index.scss';

const SortitionMachine = (props) => {

    const contentNumbers = useRef(null);
    const [lastNumber, setLastNumber] = useState(null);

    let numbers = Array(75).fill(0);
    numbers = numbers.map((item, id) => id + 1 );
    
    useEffect(() => {

        Array.prototype.map.call(contentNumbers.current.querySelectorAll('.number'), (item)=> {
            item.classList.remove('active');
        });

        props.sortitionNumbers.map((item)=> {
            contentNumbers.current.querySelector(`.number-${item}`).classList.add('active');

        });
        
        setLastNumber(props.sortitionNumbers.length);

    }, [props]);


    return (
        <div className="sortition-machine">
           <div className="icon">
                {
                    lastNumber || ''
                }
           </div>
           <div ref={contentNumbers} className="content">
            { 
                    numbers.map(item => {
                    return <div onClick={(e) => {e.currentTarget.classList.toggle('active'); }} key={`sort-number-${item}`} className={`number number-${item}`}>
                            {item}
                        </div>
                    })
            }
           </div>
        </div>
    )

};

export default SortitionMachine;