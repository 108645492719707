import React, {useState} from 'react';
import {Icon} from 'react-icons-kit';
import {star} from 'react-icons-kit/fa/star';

import '../../../../assets/style/components/modalwin/_index.scss';

const ModalWin = (props) => {

    const [close, setClose] = useState('');

    const executeClose = () => {

        setClose('close');

        setTimeout(()=>{
            props.close();
        }, 550);
    }

    console.log(props);

    return (
        <div className={`block-moda-win bg-sunshine ${close}`}>
            <div className="modal">
                <div className="area-animation">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <header>
                    <div className="star">
                        <Icon size="72" icon={star} /> 
                    </div>
                </header>
                <div className="letters-bingo">
                    <div letter="B" className="letter"></div>
                    <div letter="I" className="letter"></div>
                    <div letter="N" className="letter"></div>
                    <div letter="G" className="letter"></div>
                    <div letter="O" className="letter"></div>
                </div>
                <div className="result-bingo">
                    {/* <span><strong>Jogo ID: </strong> 1231321</span> */}
                    <div>
                        Vencedor{props.winsNames.length > 1 ? 'es' : ''}
                    </div>
                    {

                        props.winsNames ?
                            <>
                            {
                                props.winsNames.map(item => {
                                 return <div><strong>ID: </strong> {item}</div>
                                })
                            }
                            </>
                        :
                        ''

                        // (props.winsNames||[])(item => {
                        //     return <span><strong>Jogo ID: </strong> item</span>
                        // })
                    }
                </div>
                <footer>
                    <div onClick={()=> { executeClose() }} className="button-close">
                        Fechar
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default ModalWin;