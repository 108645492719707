//const Peer = window.Peer;

class ShareGame{

    lastPeerId;
    peer;
    peerId;
    shareId;
    conn;
    events;
    isServer;

    static CONFIG = { 'iceServers': [{ 'url': 'stun:stun.l.google.com:19302' }] };
    static ON_OPEN_SERVER = 'ON_OPEN_SERVER';
    static ON_JOIN_SERVER = 'ON_JOIN_SERVER';
    static ON_SEND_MESSAGE = 'ON_SEND_MESSAGE';
    static ON_RECEIVE_MESSAGE = 'ON_RECEIVE_MESSAGE';

    constructor(_isServer = true){
        this.isServer =_isServer;
        this.conn = [];
        this.events = {};
        this.peer = new window.Peer(this.CONFIG, {debug: 2});

        this.peer.on('open', _id => {

          /*  if(this.peer.id === null){
                this.peerId = this.lastPeerId;
            }else{
                this.lastPeerId = this.peerId;
            }*/

            if(this.lastPeerId){
                this.lastPeerId = this.peer.id;
            }
            
    
            this.triggerHandler(ShareGame.ON_OPEN_SERVER);
        });

        if(_isServer){
            
            this.peer.on('connection', _c => {

                console.log('Connection SERVER', _c);

                /*if(this.conn){
                    _c.on('open', () =>{
                        _c.send('Client Connect. Connectionn close');
                        _c.close();
                    });
                    return;
                }*/

                //this.conn = _c;

                this.conn.push(_c);

                this.triggerHandler(ShareGame.ON_JOIN_SERVER, _c);

                this.ready();

            });
        }

        this.peer.on('disconnected', () => {
            this.peer.id = this.lastPeerId;
            this.peer._lastServerid = this.lastPeerId;
            this.peer.reconnect();
        });

        this.peer.on('close', () => {

        });

        this.peer.on('error', _error => {

        });


    }

    on(_name, _callback){
        if(!this.events[_name])
            this.events[_name] = [];
        this.events[_name].push(_callback);
    }

    triggerHandler = (_event, _params = null) => {
        if(this.events[_event]){
            this.events[_event].forEach(element => {
                element(_params);
            });
        }
    }

    getId = () => {
        return this.peer.id;
    }

    join = (_token) => {

        if(!this.isServer){
            if(this.conn[0])
                this.close();
                
            this.conn.push(this.peer.connect(_token, {reliable: true}));

            this.conn.map(_conn => {
                
                
                    
                _conn.on('data', _data=> {
                    console.log('Message',_conn,_data);
                });
    
                _conn.on('open', _data => {
                    console.log('JOIN', _data);

                    console.log('JOIN WEBRTC', _conn);
                    this.triggerHandler(ShareGame.ON_JOIN_SERVER, _conn);
                });
    

                return _conn;
            });

           // console.log(this.conn);


        }

    }

    ready = () => {


        this.conn.map(_conn => {

            _conn.on('data', _data => {
                console.log('Message',_conn,_data);
                this.triggerHandler(ShareGame.ON_RECEIVE_MESSAGE, _data);
            });

            _conn.on('close', _data => {
                _conn = null;
            });


            return _conn;
        });

    }

    send = (_message) => {


        this.conn.map(_conn => {

            if(_conn && _conn.open){
                _conn.send(_message);
            }else{
    
            }

            return _conn;
        });

    }

    end = () =>{

        this.conn.map(_conn => {
            _conn.close();
            return _conn;
        })

    }
}

export default ShareGame;