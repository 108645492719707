import React, {useState, useEffect, createElement} from 'react';
import {Icon} from 'react-icons-kit';
import {powerOff} from 'react-icons-kit/fa/powerOff';
import {cog} from 'react-icons-kit/fa/cog';
import {questionCircle} from 'react-icons-kit/fa/questionCircle';
import {trophy} from 'react-icons-kit/fa/trophy'

import logo from '../../../../assets/image/logo.png';
import '../../../../assets/style/game.scss';

import ShareGame from '../../../../services/webrtc';
import GameSortition from '../../../../services/gamesortition';
import ModalWin from '../../../../components/ui/game/modalwin';
import SortitionMachine from '../../../../components/ui/game/sortitionmachine';
import Chat from '../../../../components/ui/game/chat';

import GameHelp from '../../../game/help';
import GameConfig from '../../../game/config';

import Bot from '../../../../services/bot';
import Ticket from '../../../../components/ui/game/ticket';



let gameServer;
let gameSortition;
let user;
let userList;
let players;
let chatMessage;
let bots;
let listWins;

const GameAdmin = (props) => {

    const [gameId, setGameId] = useState('');
    const [isGamePlay, setIsGamePlay] = useState(false);
    const [modalwin, setModalWin] = useState('');
    const [areaPages, setAreaPages] = useState('');
    const [chatText, setChatText] = useState('');
    const [playersList, setPlayersList] = useState([]);
    const [totalGamePlay, setTotalGamePlay] = useState(0);
    const [totalWins, setTotalWins] = useState(0);
    const [totalBots, setTotalBots] = useState(10);
    const [listBall, setListBall] = useState([]);

    const gamePlay = async () => {
        setIsGamePlay(true);

        const bot = new Bot(totalBots);

        if(bots?.length !== totalBots){
            bots = await bot.generateBots();
        }

        listWins = [];

        setTotalGamePlay(totalGamePlay + 1);
        gameSortition.randomizeNumbers();
        gameSortition.startGameSortition();

       /* setTimeout(()=> {
            gameSortition.setEnded(true);
            callWin();
        }, 3000);*/
    };

    const callNewNumber = (_number) => {
        setListBall(gameSortition.getSortitionNumbers());
    }
    
    const getColorTicket = () => {
        return ['rose', 'green', 'yellow'].sort(() => Math.random() * -1 + 0.5)[0];
    }

    const serverCreate = () => {
        console.log(gameServer);
        if(!players)
            players = [];

        players.push(gameServer.getId());
        setGameId(gameServer.getId());
        setPlayersList(players);
    }

    const callWin = (_user) => {
        gameSortition.setEnded(true);

        listWins.push(_user);

        if(_user === 'Você')
            setTotalWins(totalWins + 1);

        const win = createElement(ModalWin, {close: closeWin, winsNames: listWins});
        setModalWin(win);
    }

    const closeWin = () => {
        setModalWin('');
        setIsGamePlay(false);
    }

    const joinServer = (_user) => {
        
        players.push(_user.peer);

        console.log('JOIN SERVER', _user.peer);
        console.log(players);
        setPlayersList(players);
    }

    const receiveData = (_data) => {
        gameServer.send(_data);
        console.log('Receive', _data);
    }

    const sendMessageChat = (_text) => {
        if(!chatMessage)
            chatMessage = [];
        chatMessage.push(_text);

        setChatText(chatMessage);
    }

    const openPage = (_page, _props = {}) => {

        const page =  _page ? createElement(_page, _props) : '';
        setAreaPages(page);
    }

    const closeGame = () => {
        props.history.push(''); 
    }

    useEffect(()=>{

        gameServer = new ShareGame();
        gameServer.on(ShareGame.ON_OPEN_SERVER, serverCreate);
        gameServer.on(ShareGame.ON_JOIN_SERVER, joinServer);
        gameServer.on(ShareGame.ON_RECEIVE_MESSAGE, receiveData);

        gameSortition = new GameSortition();
        gameSortition.on(GameSortition.ON_SORTITING, callNewNumber);


        userList = {};

    }, []);

    return(
        <div className="game-admin">
            <header>
                <div className="logo">
                    <img src={logo} alt="Bingo Star"/>
                </div>
                <div className="title">
                    Share Mode
                </div>
                <div className="data">
                    <strong>Total de Jogos:</strong> {totalGamePlay} <strong>Total de Vitórias:</strong> {totalWins}
                </div>
                <div className="button">
                <Icon onClick={() => { openPage(GameHelp, {close: ()=> { openPage(null); } }); }} className="ranking" size="30" icon={trophy} />
                    <Icon onClick={() => { openPage(GameHelp, {close: ()=> { openPage(null); } }); }} className="help" size="30" icon={questionCircle} />
                    <Icon onClick={() => { openPage(GameConfig, {close: ()=> { openPage(null); } }); }} className="config" size="30" icon={cog} />
                    <Icon onClick={()=>{ closeGame(); }} className="close" size="30" icon={powerOff} /> 
                </div>

            </header>
            <section className="area-game">
                {
                    !isGamePlay ?
                    <section className="current-game">
                        <div className="title">Jogo Atual</div>
                        <div>
                            {/* {
                                gameId ?
                                <>Compartilhe essa url: {`${window.location.href}/Room/${gameId}`} </>
                                : ''
                            } */}
                        </div>
                        <div>
                            Total de Jogadores:
                            <input min={1} value={totalBots} onInput={(e)=> { setTotalBots(e.currentTarget.value); }} type="number" name="bots" id="bots"/>
                        </div>
                        <div>
                            <button onClick={()=>{ gamePlay(); }} className="button-start">
                                Iniciar Jogo
                            </button>
                        </div>
                    </section>
                    :
                    <>
                        <SortitionMachine sortitionNumbers={listBall} />

                        <Ticket color={'green'} ticketNumber={'Você'} onWin={callWin} sortitionNumbers={listBall} />

                        <div className="list-bots">
                        {
                            bots ?
                                <>
                                   { 
                                        bots.map((bot, id) => {
                                            return <Ticket ticketNumber={bot} key={`ticket-${id}`} onWin={callWin} sortitionNumbers={listBall} color={getColorTicket()} />
                                        })
                                    }
                                </>
                            :
                            ''
                        }
                        </div>

                    </>
                }
            </section>

            {/* <section className="area-chat">
                <Chat chatText={chatText} onSubmit={sendMessageChat} players={playersList} />
            </section> */}

            {areaPages}

            {modalwin}

        </div>
    )

}

export default GameAdmin;