import React from 'react';
import {Switch, BrowserRouter, Route } from 'react-router-dom';

import App from '../../../App';
import GameAdmin from '../../../pages/test/game/admin';
import Receive from '../../../pages/test/webrtc/receive';

const RouterTest = (props) => {

    return(
        <BrowserRouter>
            <Switch>
                <Route path="/Game" component={GameAdmin} />
                <Route path="/Receive/:id?" component={Receive} />
                <Route path="" component={GameAdmin} />
            </Switch>
        </BrowserRouter>
    )

}

export default RouterTest;