import React from 'react';
import {Icon} from 'react-icons-kit';
import {chevronLeft} from 'react-icons-kit/fa/chevronLeft';

import '../../../assets/style/page/config/_index.scss';

const GameConfig = (props) => {
    return(
        <div className="game-config">
            <header>
                <div>
                     <Icon className="close" onClick={props?.close} size="30" icon={chevronLeft} />
                </div>
                <div>
                </div>
                <div></div>
            </header>
            <section>
                <p className="title">
                    Configurações
                </p>
                <div>
                    Aqui você consiguirá configurar como será o funcionamento do seu jogo.
                </div>
            </section>
        </div>
    )
};

export default GameConfig;