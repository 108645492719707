import React, {useState, useEffect, useRef} from 'react';
import {Icon} from 'react-icons-kit';
import {star} from 'react-icons-kit/fa/star';

import '../../../../assets/style/components/ticket/_index.scss';

const Ticket = (props) => {


    const contentNumbers = useRef(null);

    const getNumbers = (_i, _f) => {
        let list = [];

        for (let i = _i, ii = _f; i <= ii; i++){
            list.push(i);
        }

        list = list.sort((i) => (Math.random() * -1) + 0.5);
        list.length = 5;

        list.sort((a, b)=>  a-b);

        return  list; 
    }

    const [colorTicket, setColorTicket] = useState('');

    const [starLine, starColumn] = [2, 2];

    const cardDemo = [getNumbers(1, 15), getNumbers(16, 30), getNumbers(31, 45), getNumbers(46, 60), getNumbers(61, 75)];

    const [numbers, setNumbers] = useState([]);
    const [ticketNumber, setTicketNumber] = useState('');

    const isCompleteRow = () => {
        let result = false;

        for(let i = 0, ii = 5; i < ii; i++){
            let total = Array.prototype.filter.call(contentNumbers.current.querySelectorAll(`.column`), item => {
                return item.querySelectorAll('.number')[i].classList.contains('active');
            });

            if(total === 5)
                result = true;
        }

        return result;
    }

    const isCompleteColumn = () => {
        let result = false;
        
        Array.prototype.map.call(contentNumbers.current.querySelectorAll(`.column`), item => {
            if(item.querySelectorAll('.active').length === 5)
                result = true;
            return item;
        });

        return result;
    }

    const isCompleteDiagonal = () => {
        let result = false;

        let total = Array.prototype.filter.call(contentNumbers.current.querySelectorAll(`.column`), (item, id) => {
            return item.querySelectorAll('.number')[id].classList.contains('active');
        });

        if(total === 5)
                result = true;

        total = Array.prototype.filter.call(contentNumbers.current.querySelectorAll(`.column`), (item, id) => {
            return item.querySelectorAll('.number')[4 - id].classList.contains('active');
        });

        if(total === 5)
                result = true;

        return result;
    }

    const isCompleteEdge = () => {
        let result = false;

        const refs = [0, 4];
        let total = 0;
        refs.map(item => {
            const columns = contentNumbers.current.querySelectorAll(`.column`)[item];
            if(columns){
                refs.map(elm=> {
                    if(columns.querySelectorAll('.number')[elm].classList.contains('active'))
                        total++;
                    return elm;
                })
            }

            return item;
        });

        if(total === 4)
            result = true;

        return result;
    }

   
    useEffect(() => {
        setColorTicket(props.color);
        setTicketNumber(props.ticketNumber || Math.round(Math.random() * 100000));
        setNumbers(props.numbers || cardDemo);

    }, [])

    useEffect(() => {

        (props.sortitionNumbers|| []).map((item)=> {
            contentNumbers.current.querySelector(`.number-${item}`)?.classList.add('active');
            return item;
        });

        if(isCompleteRow() || isCompleteColumn() || isCompleteDiagonal() || isCompleteEdge()){
            console.log('Bingo', ticketNumber);
            props.onWin(ticketNumber);
        }
        

    }, [props.sortitionNumbers]);

    return (
        <div className={`ticket ${colorTicket|| ''}`}>
          <header>
            <div letter="B" className="letter"></div>
            <div letter="I" className="letter"></div>
            <div letter="N" className="letter"></div>
            <div letter="G" className="letter"></div>
            <div letter="O" className="letter"></div>
          </header>
          <section ref={contentNumbers}>

            {
                numbers.map( (column, c) => {
                    return <div key={`column-${c}`} className="column">
                        {
                            column.map((number, l) => {
                                return <div key={`${props.ticketNumber || Math.random()}-${l}`} onClick={(e) => {e.currentTarget.classList.toggle('active'); }} className={`number ${(starColumn === c && starLine === l) ? 'star active' : 'number-' + number}`}>
                                    {(starColumn === c && starLine === l) ? 
                                        <Icon size="30" icon={star} /> 
                                        : 
                                        <>
                                            {number}
                                            <svg viewBox="0 0 100 100" className="check">
                                            <path d={"M34.745,7.183C25.078,12.703,13.516,26.359,8.797,37.13 c-13.652,31.134,9.219,54.785,34.77,55.99c15.826,0.742,31.804-2.607,42.207-17.52c6.641-9.52,12.918-27.789,7.396-39.713 C85.873,20.155,69.828-5.347,41.802,13.379"} ></path>
                                            </svg>
                                        </>
                                        }
                                </div>                  
                            })
                        }
                    </div>    
                })
            }

          </section>
          <footer>
              Ticket: {ticketNumber}
          </footer>
        </div>
    );

}

export default Ticket;