import React, {useEffect} from 'react';

import ShareGame from '../../../../services/webrtc';

let server;

const Receive = (props) => {

    console.log(props)

    //const [idServer, setIdServer] = useState('');


    const connectionOpenServer = () => {
        console.log('PEER-ID - CONNECTION', server.getId());
        server.join(props.match.params.id)  ;
    }

    const sendMessage = () =>{
        server.send('TESTE RECEIVE');
    }

    useEffect(() => {
        console.log('Start WebRTC');
        server = new ShareGame(false);
        server.on(ShareGame.ON_OPEN_SERVER, connectionOpenServer);

    });


    return(
        <div>
            {props.match.params.id}
            <hr/>
            <button onClick={()=> { sendMessage()}}>Send Message</button>
        </div>
        )

}

export default Receive;