import React, {useState, useEffect, createElement} from 'react';

import './assets/style/test.scss';

import Ticket from './components/ui/game/ticket';
import Ball from './components/ui/game/ball';
import SortitionMachine from './components/ui/game/sortitionmachine';
import ModalWin from './components/ui/game/modalwin';

import GameSortition from './services/gamesortition';
import Bot from './services/bot';

const gamesort = new GameSortition();

const App = () => {

  const [listNumbers, setListNumbers] = useState([]);

  const [listBots, setListBots] = useState([]);

  const [modalwin, setModalWin] = useState('');
  
  const numberNew = (e) => {
    setListNumbers(gamesort.getSortitionNumbers());
  }

  const endSortition = (_ticket) => {
    gamesort.setEnded(true);
    console.log('Cartela vencedora', _ticket);
    callBingo();
  }
  
  const testSorter = (e) => {
    console.log(gamesort);
    gamesort.randomizeNumbebrs();
    gamesort.startGameSortition();
  }

  const getColorTicket = () => {
    return ['rose', 'green', 'yellow'].sort(() => Math.random() * -1 + 0.5)[0];
  }

  const callBingo = () => {
    const win = createElement(ModalWin, {close: ()=>{ setModalWin('') }});

    setModalWin(win);
  }

  
  useEffect(() => {
    
    const getBot = async () => {
      const bot = new Bot(5);
      const list = await bot.generateBots();
      setListBots(list);
    }
    
    getBot();
    gamesort.on(GameSortition.ON_SORTITING, numberNew);
  }, []);
  
 

  return (
    <div className="App">

        
        {
          listBots.length > 0 ?
            <button onClick={()=>{ testSorter() }}>Inicia</button>
          : 
          ''
        }
        <hr/>

        {
          listNumbers.reverse().map(item => {
            return <Ball key={`ball-${item}`} number={item} />
          })
        }

        <hr/>

        {/* <Ticket onWin={endSortition} sortitionNumbers={listNumbers} color="green" />    
        <Ticket onWin={endSortition} sortitionNumbers={listNumbers} color="rose" />
        <Ticket onWin={endSortition} sortitionNumbers={listNumbers} color="yellow" />
        <Ticket onWin={endSortition} sortitionNumbers={listNumbers} />
        <Ticket onWin={endSortition} sortitionNumbers={listNumbers} />
        <Ticket onWin={endSortition} sortitionNumbers={listNumbers} /> */}
        
         <Ticket ticketNumber={'SUA'} onWin={endSortition} sortitionNumbers={listNumbers} />
        
        {
          listBots.map((bot, id) => {
            return <Ticket ticketNumber={bot} key={`ticket-${id}`} onWin={endSortition} sortitionNumbers={listNumbers} color={getColorTicket()} />
          })
        }

        <hr/>

        <SortitionMachine sortitionNumbers={listNumbers} />

        <hr/>
        
        <button onClick={()=>{ callBingo() }}>Bingo</button>

        {modalwin}
    </div>
  );
}


export default App;
